.urgency-status-badge {
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 12px;
    color: white;
}

/* Rechazada */
.urgency-status-badge__-1,
.urgency-status-badge__1 {
    background-color: #00a651;
}

/* Pdte confirmar */
.urgency-status-badge__0 {
    background-color: #981b1b;
}