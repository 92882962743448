.status-2{
  background-color: #e8b51b;
  color: white;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 12px;
}
.status-1{
  background-color: #981b1b;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

.status-3{
  background-color: #003471;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

.status-4{
  background-color: #009ba6;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

.status-5{
  background-color: #ccc;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

.status-7{
  background-color: #00a651;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

.selectChangeStatus{
  display: inline-block;
  min-width: 300px;
  margin: 10px 0px;
  vertical-align: middle;
}

.rdt_TableCol_Sortable{
  font-weight: bold;
}
.rdt_TableHeadRow{
  border-bottom-width: 2px !important;
}

.modal-body .fifty{
  width: 100%;
  max-width: 180px;
  display: inline-block;
  margin: 10px 0px;
}

.modal-body .btn-default{
  margin-left: 10px;
}

.modal-body .urgency-container {
  display: inline-block;
  margin: 10px 10px;
  width: 50%;
}

.modal-body .urgency-container textarea {
  width: 80%;
}

.modal-body .urgency-button-row {
  margin-top: 10px;
}

.red {
  color: red;
  font-weight: bold;
}


.favorite{
  color: #aaa;
  cursor: pointer;
}

.rdt_TableRow:hover .favorite{
  color: #d4b043;
}

.__react_component_tooltip p {
  line-height: 1.5;
  padding: .5rem;
  font-size: 1.2rem;
  font-weight: normal;
}

.modal-row {
  display: flex;
}

.modal-row .datepicker {
  margin: 0;
  margin-left: 1rem;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.input-control {
  border: 1px solid #aaa;
  padding: 1rem;
}

.row-control {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}