.summary-container {
    margin-top: 25px;
}

.summary-container > div {
    padding: 20px 20px;
}

.promotions-container {
    margin-top: 25px;
}

.promotions-container > div {
    padding: 20px 20px;
}

.promotions-container .promotion-banner {
    width: 100%;
}