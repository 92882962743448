.pickup-status-badge {
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 12px;
    color: white;
}

/* Rechazada */
.pickup-status-badge__-1 {
    background-color: #00a651;
}

/* Pdte confirmar */
.pickup-status-badge__1 {
    background-color: #981b1b;
}

/* Confirmada */
.pickup-status-badge__2 {
    background-color: #e8b51b;
}

/* Entregada o Recogida */
.pickup-status-badge__3 {
    background-color: #00a651;
}