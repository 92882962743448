.logo-loading{
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  margin-top: -50px;
}

.sidebar-menu{
  min-height: auto !important;
}

.sidebar-menu #main-menu li.highlighted {
  background-image: url("../images/sidemenu-bg.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.sidebar-menu #main-menu li.highlighted i,
.sidebar-menu #main-menu li.highlighted span {
  color: white !important;
}

.canvas-container{
  position:relative; width:350px; height:450px; float:left;
  -webkit-transform: translateX(-40%);
  -moz-transform: translateX(-40%);
  transform: translateX(-40%);
  margin-left: 50%;
}

.footButton{
  margin-bottom: 15px;
}

.footButton.active,
.footButton.hover{
  background: #ccc;
}

.footButton:hover{
  background: #eee;
}

.canvasFix, #canvas{
  position: absolute;
  top: 0px;
  left: 0px;
}

.validate-has-error{
  border-color: #cc2424;
  color: #cc2424;
  display: inline-block;
  margin: 0px;
}

span.validate-has-error{
  font-size: 11px;
  margin-top: -15px !important
}

.validate-has-error .form-control{
  margin-bottom: 0px;
}

.img-thumbnail{
  max-height: 100%;
  min-height: 150px;
}

.title-section {
  color: #0088CE;
  font-size: 25px;
}


/* section tab-pane */
.tab-pane h3{
  margin-bottom: 20px;
}
.tab-pane h4{
  margin-top: 22px;
  padding-bottom: 5px;
  border-bottom: 2px solid #949494;
  color: #949494;
  font-weight: bold;
}
.tab-pane label.pre-label:hover{
  color: black;
  cursor: pointer;
}

/* toast */
.Toastify__toast{
  min-height: 45px;
}

.Toastify__toast--success{
  background: #00a65a;
}

.Toastify__toast--error{
  background: #f56954;
}

/* datatable */
.bytegr{
  padding: 20px 0px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

/* Icon youtube */
.icon-youtube{
  padding: 2px;
  position: absolute;
  z-index: 9;
  cursor: pointer;
  top: 10px;
  right: 10px;
  border-radius: 25px;
  font-size: 10px;
  border: 1px solid grey;
  background: white;
}

.breadcrumb.bc-3 {
  display: flex;
  align-items: center;
}

.breadcrumb .breadcrumb-home {
  font-size: 18px;
}

.breadcrumb .breadcrumb-home a {
  display: flex;
  align-items: center;
}

.breadcrumb .breadcrumb-home a img {
  width: 50px;
  height: 50px;
}