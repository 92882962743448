#inputDate {
  height: 38px;
  border-color: #cccccc;
}
.row-pdf {
  display: flex;
  justify-content: flex-end;
  padding: 1.5rem;
  gap: 2rem;
}
